<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Опрос DAO</h5>
    <Button
      label="Создать опрос"
      class="p-button-primary mb-4"
      @click="create"
    />
    <DataTable
      :value="daoList"
      data-key="id"
      :loading="loading"
      responsiveLayout="scroll"
      paginator
      lazy
      :rows="rows"
      :totalRecords="totalItems"
      @page="setPage"
    >
      <template #empty> No customers found. </template>
      <template #loading> Loading customers data. Please wait. </template>

      <Column header="id" field="id" />
      <Column header="Название" field="name" />
      <Column header="Кол Токенов" field="tokenLimit" />
      <Column header="Начало" field="startAt">
        <template #body="{ data }">
          {{ moment(data.startAt).format('DD.MM.YYYY') }}
        </template>
      </Column>
      <Column header="Конец" field="endAt">
        <template #body="{ data }">
          {{ moment(data.endAt).format('DD.MM.YYYY') }}
        </template>
      </Column>
      <Column header="Статус" field="status">
        <template #body="{ data }">
          {{ data.status in statuses ? statuses[data.status] : "-" }}
        </template>
      </Column>

      <Column class="bg-white border-none flex justify-content-end">
        <template #body="slot">
          <Button
            :disabled="slot.data.issuedAt !== undefined"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning m-1"
            @click="edit(slot.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger m-1"
            @click="del(slot.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="createEditDialog"
    :header="header"
    style="width: 700px"
    class="p-fluid"
  >
    <div>
      <div class="flex">
        <div class="flex-column col-6">
          <label id="productname">Название опроса</label>
          <InputText class="mt-1 mb-3" id="productname" v-model="nameDao" />
          <label id="productname">Количество токенов для голосования</label>
          <InputNumber class="mt-1 mb-3" id="productname" v-model="tokensDao" />
        </div>
        <div class="flex-column col-6">
          <label id="productname">Начало голосования</label>
          <Calendar
            class="mt-1 mb-3"
            v-model="startDao"
            showIcon
            dateFormat="dd.mm.yy"
          />
          <label id="productname">Окончание голосования</label>
          <Calendar
            class="mt-1 mb-3"
            v-model="endDao"
            showIcon
            dateFormat="dd.mm.yy"
          />
        </div>
      </div>
      <div class="col-12">
        <label id="description">Описание</label>
        <InputText
          class="mt-1 mb-3"
          id="description"
          v-model="descriptionDao"
        />
        <div class='flex align-items-center'>
          <Checkbox v-model="useToken" :binary="true" id="useToken" />
          <label for="useToken">Голосовать за токены</label>
        </div>
        <Dropdown
          v-if="useToken"
          v-model="selectedToken"
          class="mt-2"
          :options="tokensList"
          option-label="nameEng"
          placeholder="Выберите токен"
        />
      </div>
    </div>

    <div
      v-if="modifyDao"
      class="flex align-items-center justify-content-center mb-5"
    >
      <router-link
        class="font-medium no-underline ml-2 text-right cursor-pointer"
        style="color: var(--primary-color)"
        :to="`/manager-events/${modifyDao.id}`"
        >Ссылка на результаты голосования опроса</router-link
      >
    </div>
    <div class="answer" v-if="answerList.length">
      <div class="p-fluid">
        <DataTable
          v-model:editingRows="editingRows"
          :value="answerList"
          editMode="row"
          dataKey="id"
          @row-edit-save="onEditAnswer"
          tableClass="editable-cells-table"
        >
          <Column field="name" header="Варианты ответов">
            <template #editor="{ data, field }">
              <InputText
                v-model="data[field]"
                placeholder="Пустая строка - удалит ответ"
              />
            </template>
          </Column>
          <Column v-if="addAns" :rowEditor="true" bodyStyle="text-align:center">
          </Column>
        </DataTable>
      </div>
    </div>

    <div class="distance_button" v-if="modifyDao && addAns">
      <div class="p-inputgroup flex">
        <InputText v-model="newAnswer" placeholder="Добавить ответ" />
        <Button
          icon="pi pi-plus"
          @click="onCreateAnswer"
          :disabled="!answerField"
        />
      </div>
    </div>
    <div class="distance_button">
      <small class="p-error flex justify-content-center">
        <span v-if="!allField">Заполните все поля</span>
        <span v-else>&nbsp;</span>
      </small>
      <Button
        :disabled="!allField"
        :label="button"
        class="p-button-primary mt-2"
        @click="modifyDao === null ? createDao() : editDao()"
      />
    </div>
  </Dialog>

  <Dialog v-model:visible="deleteDialog" header="Удалить DAO?" :modal="true">
    <div class="confirmation-content">
      <p class="text-center p-error">Вы уверены, что хотите удалить?</p>
      <p class="text-center">
        <b>{{ modifyDao.name }}</b>
      </p>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteDialog = false"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteDao"
      />
    </template>
  </Dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ManagerDao',
  data() {
    return {
      statuses: {
        draft: "Черновик",
        prepared: "Подготовка",
        ready: "Готово",
        process: "В процессе",
        closed: "Закрыто"
      },
      daoList: [],
      answerList: [],
      tokensList: [],
      nameDao: '',
      descriptionDao: '',
      tokensDao: 0,
      startDao: null,
      endDao: null,
      useToken: false,
      selectedToken: null,
      modifyDao: null,
      editingRows: [],
      newAnswer: '',
      addAnswer: false,
      createEditDialog: false,
      deleteDialog: false,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    await this.getDaoList()
    await this.getVoitingTokens();
  },

  computed: {
    allField() {
      return (
        this.nameDao.length > 5 &&
        this.startDao !== null &&
        this.endDao !== null
      )
    },
    answerField() {
      return this.newAnswer.length > 0
    },
    addAns() {
      return moment().format('DD') < moment(this.modifyDao.startAt).format('DD')
    },
    header() {
      return this.modifyDao === null ? 'Создать опрос' : 'Редактировать опрос'
    },
    button() {
      return this.modifyDao === null ? 'Создать' : 'Редактировать'
    },
  },
  methods: {
    moment(data) {
      return moment(data)
    },
    setPage(event) {
      this.page = event.page + 1
      this.getDaoList()
    },

    async getDaoList() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/dao_requests', {
          headers,
          params,
        })
        this.daoList = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api call',
          group: 'info',
          life: 3000,
        })
      }
    },

    async createDao() {
      try {
        const headers = {
          accept: 'application/json',
          'Content-Type': 'application/json',
        }
        const params = {
          name: this.nameDao,
          owner: `/api/users/${this.$auth.user().id}`,
          createdAt: moment(),
          startAt: moment(this.startDao),
          endAt: moment(this.endDao),
          description: this.descriptionDao,
          tokenLimit: this.tokensDao,
        }
        if (this.useToken) {
          params.tokenDao = "/api/au_tokens/" + this.selectedToken.id;
        }
        const resp = await this.axios.post('api/dao_requests', params, {
          headers: headers,
        })
        this.daoList.push(resp.data)
        this.createEditDialog = false
        this.$toast.add({
          severity: 'success',
          summary: 'Successfull',
          detail: 'DAO Created',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error DAO Created',
          group: 'info',
          life: 3000,
        })
      }
    },

    async editDao() {
      try {
        const headers = {
          accept: 'application/json',
          'Content-Type': 'application/merge-patch+json',
        }

        const params = {
          name: this.nameDao,
          owner: `/api/users/${this.$auth.user().id}`,
          createdAt: moment(),
          startAt: this.startDao,
          endAt: this.endDao,
          description: this.descriptionDao,
          tokenLimit: this.tokensDao,
        }
        if (this.useToken) {
          params.tokenDao = "/api/au_tokens/" + this.selectedToken.id;
        }
        await this.axios.patch(
          `api/dao_requests/${this.modifyDao.id}`,
          params,
          { headers: headers }
        )
        this.createEditDialog = false
        this.getDaoList()
        this.$toast.add({
          severity: 'success',
          summary: 'Successfull',
          detail: 'DAO Changed',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in edited DAO',
          group: 'info',
          life: 3000,
        })
      }
    },

    async deleteDao() {
      this.deleteDialog = false
      try {
        await this.axios.delete(`api/dao_requests/${this.modifyDao.id}`)
        this.daoList = this.daoList.filter((i) => i.id != this.modifyDao.id)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'DAO deleted successfully',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Delete DAO error',
          group: 'info',
          life: 3000,
        })
      }
    },

    create() {
      this.modifyDao = null
      this.nameDao = ''
      this.descriptionDao = ''
      this.tokensDao = 0
      this.startDao = null
      this.endDao = null
      this.useToken = false
      this.selectedToken = null
      this.createEditDialog = true
    },

    edit(value) {
      this.modifyDao = value
      this.nameDao = value.name
      this.tokensDao = value.tokenLimit
      this.descriptionDao = value.description
      this.startDao = moment(value.startAt).format('DD.MM.YYYY')
      this.endDao = moment(value.endAt).format('DD.MM.YYYY')
      this.useToken = "tokenDao" in value
      let daoId = value?.tokenDao
      if (daoId) {
        daoId = daoId.slice(daoId.lastIndexOf("/") + 1)
      }
      this.selectedToken = this.tokensList.find(item => item.id == daoId)
      this.createEditDialog = true
      this.getAnswerList(value)
    },

    del(value) {
      this.modifyDao = value
      this.deleteDialog = true
    },

    async getAnswerList(value) {
      try {
        const resp = await this.axios.get(
          `api/dao_elements?page=1&itemsPerPage=30&requestId=${value.id}`
        )
        this.answerList = resp.data
      } catch (e) {
        console.log(e)
      }
    },

    onEditAnswer(event) {
      let { newData, index } = event
      this.answerList[index] = newData
      if (!newData.name.length) {
        this.removeAnswer(newData)
      } else {
        this.editAnswer(newData)
      }
    },

    async onCreateAnswer() {
      try {
        const headers = {
          accept: 'application/json',
          'Content-Type': 'application/json',
        }
        const params = {
          name: this.newAnswer,
          request: `api/dao_requests/${this.modifyDao.id}`,
        }
        const resp = await this.axios.post('api/dao_elements', params, {
          headers: headers,
        })
        this.answerList.push(resp.data)
        this.newAnswer = ''
        this.$toast.add({
          severity: 'success',
          summary: 'Successfull',
          detail: 'Answer created',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error created answer',
          group: 'info',
          life: 3000,
        })
      }
    },
    async editAnswer(newData) {
      try {
        const headers = {
          accept: 'application/json',
          'Content-Type': 'application/merge-patch+json',
        }
        const params = {
          name: newData.name,
          request: newData.request,
        }
        await this.axios.patch(`api/dao_elements/${newData.id}`, params, {
          headers: headers,
        })
        this.createEditDialog = false
        this.getDaoList()
        this.$toast.add({
          severity: 'success',
          summary: 'Successfull',
          detail: 'Changed',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in edited DAO',
          group: 'info',
          life: 3000,
        })
      }
    },

    async removeAnswer(newData) {
      try {
        await this.axios.delete(`api/dao_elements/${newData.id}`)
        this.createEditDialog = false
        this.getDaoList()
        this.$toast.add({
          severity: 'success',
          summary: 'Successfull',
          detail: 'Deleted',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in delete DAO',
          group: 'info',
          life: 3000,
        })
      }
    },

    async getVoitingTokens() {
      const params = {
        pagination: false,
        isVoiting: true
      }
      const resp = await this.axios.get("/api/au_tokens", { params: params });
      this.tokensList = resp.data;
    }
  },
}
</script>

<style scoped>
.distance_button {
  margin-top: 10px;
}
</style>
